

export var debug_log_active = false
export var close_panel_button_selector = '.close-panel-button'
export var dropdown_content_wrapper_selector = null
export var tooltip_content_wrapper_selector = null


function initialize_settings() {

    const metas = document.getElementsByTagName('meta');
    let metas_count = 0
    let do_log = false

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === 'stimulus-dropdown') {
            const content = metas[i].getAttribute('content')
            metas_count += 1

            if (content.includes('console-debug-log=true')) {
                debug_log_active = true
                do_log = true
                console.log('Stimulus Dropdown: Logging activated')
            }

            if (content.includes('close-panel-button-selector=')) {
                let pattern = /(?<=close-panel-button-selector=)[\s\S]*(?=;)/i;
                close_panel_button_selector = content.match(pattern)[0];
                if (do_log) {
                    console.log('set close-panel-button-selector to:', close_panel_button_selector)
                }
            }

            if (content.includes('dropdown-content-wrapper-selector=')) {
                let pattern = /(?<=dropdown-content-wrapper-selector=)[\s\S]*(?=;)/i;
                dropdown_content_wrapper_selector = content.match(pattern)[0];
                if (do_log) {
                    console.log('set dropdown-content-wrapper-selector to:', dropdown_content_wrapper_selector)
                }
            }

            if (content.includes('tooltip-content-wrapper-selector=')) {
                let pattern = /(?<=tooltip-content-wrapper-selector=)[\s\S]*(?=;)/i;
                tooltip_content_wrapper_selector = content.match(pattern)[0];
                if (do_log) {
                    console.log('set tooltip-content-wrapper-selector to:', tooltip_content_wrapper_selector)
                }
            }
        }
    }

    if (do_log) {
        console.log(`found ${metas_count} meta-tags (html-header, for settings)`)
    } else {
        debug_log_active = false
    }


}

initialize_settings()


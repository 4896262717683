import {close_panel_button_selector} from './initialize-settings.js'

import {Controller} from "@hotwired/stimulus"
import {place_panel, place_me} from "./floating_ui_functions.js";
import {pull_content_from_server, debug_log} from "./functions.js";


let close_on_click_outside_listener_added = false
export default class extends Controller {

    connect() {

        // add reference to this stimulus instance to the element for being able calling this methods from outside
        this.element[this.identifier + '_controller'] = this
        this.element.addEventListener('place-panel', () => {
            place_panel(this.element)
        })

        this.element.addEventListener('click', (e) => this.toggle(e))
        this.element.addEventListener('place-panel', () => place_panel(this.element))

        const target_id = this.element.getAttribute('data-toggle')
        if (!target_id || target_id === '' || target_id === 'true') {
            this.element.setAttribute('data-toggle', '')
        }

    }


    toggle(e) {
        if (this.element.getAttribute('data-prevent-default')) {
            e.preventDefault()
        }
        e.stopPropagation()
        debug_log('toggle panel', e)
        const target_id = this.element.getAttribute('data-toggle')
        const panel = document.getElementById(target_id)
        if (!panel) {
            console.error(`Panel-element with ID ${target_id} not found`)
        } else if (panel.classList.contains('hide')) {
            this.open(e, panel)
        } else {
            this.close(panel)
        }
    }

    open(e, panel) {

        close_on_click_outside(e)

        if (!close_on_click_outside_listener_added) {
            close_on_click_outside_listener_added = true;
            window.addEventListener('click', close_on_click_outside)
            debug_log('Listener for close panels on click outside added')
        }

        const button = this.element


        if (panel.hasAttribute('data-set-focus')) {
            let data_focus = panel.getAttribute('data-set-focus')
            let focus_element = panel.querySelector(data_focus)
            focus_element.focus()
        }

        if (!panel.panel_initialized) {
            panel.panel_initialized = true
            panel.addEventListener('close', () => this.close(panel))
            panel.addEventListener('place-me', () => place_me(panel))
        }

        // set status

        panel.setAttribute('data-stimulus-dropdown-panel-status', 'open')
        panel.classList.remove('hide');
        this.element.classList.add('has-open-panel')
        debug_log('class .hide removed from panel', panel)

        // open events

        const open_event = new CustomEvent('dropdown-open')
        place_panel(this.element)

        panel.dispatchEvent(open_event)
        pull_content_from_server(panel)
        add_close_button_function(this.element, panel)

    }

    close(panel) {
        panel.classList.add('hide')
        this.element.classList.remove('has-open-panel')
        debug_log('class .hide added to panel', panel)
        panel.setAttribute('data-stimulus-dropdown-panel-status', 'closed')
        //this.element.classList.remove('has-panel-open');

        const open_panels = document.querySelectorAll("[data-stimulus-dropdown-panel-status='open']")
        debug_log('panel closed, open panels left:', open_panels.length)

        if (close_on_click_outside_listener_added && open_panels.length === 0) {
            close_on_click_outside_listener_added = false;
            window.removeEventListener('click', close_on_click_outside)
            debug_log('Listener for close panels on click outside removed')
        }


    }

}

function add_close_button_function(button, panel) {
    const selector = close_panel_button_selector
    const close_buttons = panel.querySelectorAll(selector)
    debug_log(`Added close-button-functions to (found within panel, by: ${selector})`, close_buttons)
    for (const btn of close_buttons) {
        btn.addEventListener('click', () => {
            const ev = new Event('close')
            panel.dispatchEvent(ev)
        })
    }
}

function close_on_click_outside(ev) {
    // climb up stack to parent panels


    let btn = ev.target
    let parent_panel_ids = []

    while (true) {

        const parent_panel = btn.closest("[data-stimulus-dropdown-panel-status='open']")
        if (parent_panel) {
            parent_panel_ids.push(parent_panel.id)

            btn = document.querySelector(`[data-toggle="${parent_panel.id}"]`)
            //console.log('INSIDE', parent_panel_ids)

        } else {
            break
        }
    }

    // close all panels except parents

    const open_panels = document.querySelectorAll("[data-stimulus-dropdown-panel-status='open']")
    for (const panel of open_panels) {
        if (!parent_panel_ids.includes(panel.id)) {
            //panel.classList.add('hide')
            const ev = new Event('close')
            panel.dispatchEvent(ev)
        }
    }
}
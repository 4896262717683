// To see this message, add the following to the `<head>` section in your
// views/layouts/application.haml
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

import 'foundation-sites'

import $ from 'jquery';

window.$ = $;

// custom javascript

import '../javascript/custom_actions'

import '../javascript/stimulus_dropdown/index'

// init stimulus

import { initStimulus } from "@csedl/stimulus-initializer";
const controllers = import.meta.glob('../javascript/**/*-controller.js', { eager: true })
initStimulus(controllers, { debug: true })

// initialize the page
window.addEventListener('load', (event) => {
    initPage();
});

window.addEventListener('turbo:render', (event) => {
    initPage();
});

window.addEventListener('resize', () => {
    const overflow_body = document.getElementById('window-overflow-body')
    if (overflow_body) {
        const btn = document.getElementById('constrain-btn')
        const event = new Event('place-panel')
        btn.dispatchEvent(event)
        window.addEventListener('scroll', () => {
            console.log('scroll')
        })
    }
})

function initPage() {
    $(document).foundation();
    console.log('foundation ready');
}
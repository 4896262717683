import {arrow, computePosition, flip, offset, shift} from "@floating-ui/dom";
import {debug_log} from "./functions.js";


export function place_panel(button, log2) {
    const t_id = button.getAttribute('data-toggle')
    let panel = document.getElementById(t_id)
    place(button, panel)
    debug_log('panel placed', log2)
}

export function place_me(panel) {
    let button = document.querySelector(`[data-toggle="${panel.id}"]`)
    place(button, panel)
}

export function place_all(scope_element, search_class) {
    const elements = scope_element.getElementsByClassName(search_class)

    for (const element of elements) {
        const e_str = (element.hasAttribute('data-toggle') ? 'place-panel' : 'place-me')
        const e = new Event(e_str)
        element.dispatchEvent(e)
    }

}

function place(button, panel) {

    let arrow_element = panel.querySelector('#arrow')
    panel.style.removeProperty('height')
    panel.style.removeProperty('left')
    panel.style.removeProperty('top')
    let p = panel.getAttribute('placement')
    let placement = (p ? p : 'bottom')

    computePosition(button, panel, {
        middleware: [flip(), offset(6), shift({padding: 5}), arrow({element: arrow_element})],
        placement: placement
    }).then(({x, y, placement, middlewareData}) => {

        Object.assign(panel.style, {
            left: `${x}px`,
            top: `${y}px`,
        });

        if (arrow_element) {

            //ARROW
            const {x: arrowX, y: arrowY} = middlewareData.arrow;
            const staticSide = {
                top: 'bottom',
                right: 'left',
                bottom: 'top',
                left: 'right',
            }[placement.split('-')[0]];
            Object.assign(arrow_element.style, {
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide]: '-4px',
            });
            debug_log('arrow placed')
        }

        constrain_to_window_borders(button, panel)
    });
}







function constrain_to_window_borders(button, panel) {
    if (button.getAttribute('data-constrain-to-window-borders') === 'true') {

        const rect = panel.getBoundingClientRect();

        if (rect.top < 0) {
            const new_top = parseFloat(panel.style.top) + Math.abs(rect.top) + 2
            console.log('constrain top', 'actual top:', panel.style.top, 'absolute top:', rect.top, 'new top:', new_top)
            panel.style.top = `${new_top}px`
        }

        const rect2 = panel.getBoundingClientRect();

        if (rect2.bottom > window.innerHeight) {
            console.log('constrain height')
            const h = window.innerHeight - rect2.top - 2
            panel.style.height = `${h}px`
        }


    }
}
import {place_panel} from "./floating_ui_functions.js";
import {
    dropdown_content_wrapper_selector,
    tooltip_content_wrapper_selector,
    debug_log_active
} from "./initialize-settings.js";

export function pull_content_from_server(panel) {
    if (panel.hasAttribute('data-src')) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', panel.getAttribute('data-src'));
        xhr.send();
        xhr.onload = function () {

            if (xhr.status !== 200) {
                alert(`Dropdown Controller, GET «${url}», Error ${xhr.status}: ${xhr.statusText}`);

            } else {
                const button = document.querySelector(`[data-toggle="${panel.id}"]`)

                // replace content
                const ctrl = button.getAttribute('data-controller');

                if (ctrl === 'csedl-dropdown' && dropdown_content_wrapper_selector) {
                    const wrapper = panel.querySelector(dropdown_content_wrapper_selector)
                    wrapper.innerHTML = xhr.response

                } else if (ctrl === 'csedl-tooltip' && tooltip_content_wrapper_selector) {
                    const wrapper = panel.querySelector(tooltip_content_wrapper_selector)
                    wrapper.innerHTML = xhr.response

                } else {
                    panel.innerHTML = xhr.response
                    console.error('fallback to replace whole panel')
                }

                place_panel(button, `after http-request "${ctrl}"`)
            }
        }
    }
}

export function debug_log(log, log2) {
    if (debug_log_active) {
        if (log2) {
            console.log(log, log2)
        } else {
            console.log(log)
        }
    }
}
